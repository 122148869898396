@font-face {
  font-family: AkzidenzGroteskBELight;
  src: url(./assets/fonts/AkzidenzGroteskBE-Light.ttf);
}

@font-face {
  font-family: AkzidenzGroteskBEMd;
  src: url(./assets/fonts/AkzidenzGroteskBE-Md.ttf);
}

@font-face {
  font-family: AkzidenzGroteskBERegular;
  src: url(./assets/fonts/AkzidenzGroteskBE-Regular.ttf);
}

@font-face {
  font-family: AkzidenzGroteskBESuper;
  src: url(./assets/fonts/AkzidenzGroteskBE-Super.ttf);
}

@font-face {
  font-family: AkzidenzGroteskBEBold;
  src: url(./assets/fonts/AkzidenzGroteskBE-Bold.otf);
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: AkzidenzGroteskBERegular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ui.input > input,
.item {
  font-family: AkzidenzGroteskBERegular;
}

p {
  font-family: AkzidenzGroteskBEMd;
}

h1 {
  font-family: AkzidenzGroteskBESuper;
}

header .ui.inline.dropdown .text {
  font-family: AkzidenzGroteskBELight;
}

.ui.button {
  font-family: AkzidenzGroteskBEMd;
}

.accordion.ui.styled .title .icon {
  color: #ee3653;
}

.ui.fullscreen.modal {
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.minimum-toast {
  color: #ffed00 !important;
}
