@import '../../variables';

.product-configurator {
  &__add-to-bag {
    background-color: white;
    display: grid;
    place-items: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 10px;

    button {
      align-items: center;
      background-color: $primaryColor;
      border-radius: 10px;
      border: none;
      color: white;
      cursor: pointer;
      display: flex;
      font-family: $mediumFont;
      font-size: 18px;
      font-weight: 500;
      height: 50px;
      justify-content: center;
      position: relative;
      text-transform: uppercase;
      width: 95%;

      &:focus {
        outline: 0;
      }

      &.disabled {
        background-color: $placeholderGray;
        cursor: default;
      }
    }
  }

  &__alcohol-warning {
    margin-top: 10px;
    h5 {
      font-family: $mediumFont;
      font-size: 13px;
      color: $charcoalGray;
      margin: 0;
    }
    span {
      font-family: $regularFont;
      font-size: 12px;
      color: $primaryColor;
    }
    p {
      font-family: $regularFont;
      font-size: 12px;
      color: $inputTextGray;
      margin-left: 25px;
    }
    label {
      font-family: $lightFont;
      font-size: 16px;
      color: $inputTextGray;
    }
  }

  &__options {
    position: relative;
    .ui.grid {
      position: inherit;
      z-index: 2;
    }
    .ui.segment {
      border-radius: 10px;
      color: $charcoalGray;
      font-family: $mediumFont;

      .title {
        font-size: 20px;
      }

      .description {
        color: $gray;
        font-family: $lightFont;
        font-size: 13px;
        margin: 5px 0px;
      }

      .price {
        font-size: 16px;
      }
    }
  }

  &__notes {
    margin: 10px 0 20px;
  }

  &__bottom-padding {
    padding: 0 0 70px 0; // This offsets the "Add to Bag" button fixed to the bottom
  }
}
