@import '../../../variables';

$black: #47435d;
@mixin header {
  color: $black;
  font-family: AkzidenzGroteskBEBold;
  letter-spacing: 0.72px;
  text-transform: uppercase;
}

.suite-selection {
  max-width: 1125px;
  min-height: 100%;
  margin: 70px auto 30px;

  h2 {
    @include header;
    color: $primaryColor;
    font-size: 28px;
  }

  &__venue {
    border-bottom: 1px solid lightgray;
    margin-top: 30px;
    padding-bottom: 30px;

    &:last-child {
      border: none;
    }

    h3 {
      @include header;
      font-size: 24px;
    }

    &__valid-locations {
      display: flex;
      flex-flow: row wrap;

      &__valid-location {
        margin-top: 20px;
        width: 100%;

        @media (min-width: 500px) {
          width: 33.3%;
        }

        h4 {
          @include header;
          font-size: 20px;
        }

        &__suites {
          display: flex;
          flex-flow: row wrap;
          margin: 0 -5px;

          &__suite {
            border: 1px solid $black;
            border-radius: 4px;
            color: $black;
            cursor: pointer;
            margin: 5px;
            padding: 10px 30px;
          }
        }
      }
    }
  }
}
